.app {
    text-align: center;
    color: black;
}

.formulario {
    background-color: rgb(117, 28, 236);
}

.formulario .input+.input {
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
}

.btn {
    margin-bottom: 10px;
}

body {
    background-color: rgb(254, 216, 50);

}

.contenedor {
    margin-top: 30px;
    background-color: rgb(117, 28, 236);
    box-shadow: 0px 5px 10px black;
}

.item {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.item .product-container+.product-container {
    margin-left: 30px;
}

.product-container {
    background-color: white;
    margin-top: 5px;
    margin-bottom: 5px;
}

.product-container-item {
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.contenedor-producto-item {
    margin-left: 5px;
    margin-right: 5px;
}

@media screen and (max-width: 600px) {
    .product-container {
        /*align vertically*/
        flex-direction: column;

    }
}

.input-container {
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
}

.input-container p {
    color: white;
    font-size: large;
}

.input-container input,
select {
    width: 100%;
    height: 30px;
    border-radius: 5px;
    border: 1px solid black;
}

.enviar {
    margin-top: 40px;
}